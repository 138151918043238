import { adminClient } from "@api/admin/AdminClient";
import { withAdminMenuLayout } from "@components/admin/Decorators";
import { createErrorToast, createInfoToast } from "@components/ToastContainer";
import { NoSsr } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { getBasePath } from "../../../utils/swaggerClientUtils";

export default withAdminMenuLayout(() => {

    const handleHangfireLockTableClearButtonClick = async () => {
        try {
            await adminClient().adminHangfireLockTableClearPut({});
            createInfoToast("Succesfully attemped to clear hangfire lock table. Please verify that hangfire jobs are running normally.")
        } catch (error) {
            createErrorToast("Failed attempting to clear hangfire lock table. This operation must be manually completed/verified in PostgreSQL.")
        }
    }

    if (typeof window === "undefined")
        return null;

    return <>
        <Button
            onClick={async () => await handleHangfireLockTableClearButtonClick()}
            color="secondary"
            variant="contained"
            style={{ marginBottom: "10px" }}
        >
            Clear Hangfire Lock Table
        </Button>
        <NoSsr>
            <iframe title="Hangfire" src={getBasePath() + "hangfire/recurring?from=0&count=5000"} style={{
                width: '100%',
                height: 'calc(100vh - 120px)',
                border: 0
            }} />
        </NoSsr>
    </>
});